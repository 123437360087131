import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import * as packageJson from '../package.json';
import { Props } from 'docz';
import Playground from '../../../src/Playground';
import { UiSpacing, Sizing } from '../src/';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uispacing"
    }}>{`UiSpacing`}</h1>
    <hr></hr>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/foundation/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`This component is used to add spacing around components`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation-"
    }}>{`Installation 🧰`}</h2>
    <p>{`This spacing component is exported as part of the foundation package: @uireact/foundation`}</p>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage-️"
    }}>{`Usage 🛠️`}</h2>
    <Playground __position={1} __code={'<UiSpacing margin={{ all: \'five\' }}>Nice margin dude!</UiSpacing>'} __scope={{
      props,
      DefaultLayout,
      packageJson,
      Props,
      Playground,
      UiSpacing,
      Sizing,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiSpacing margin={{
        all: 'five'
      }} mdxType="UiSpacing">Nice margin dude!</UiSpacing>
    </Playground>
    <p><strong parentName="p">{`UiSpacing`}</strong>{` renders a div to which it assigns its styling and wraps the children`}</p>
    <h2 {...{
      "id": "props-️"
    }}>{`Props 🎛️`}</h2>
    <Props of={UiSpacing} mdxType="Props" />
    <h2 {...{
      "id": "details-"
    }}>{`Details 🤓`}</h2>
    <p>{`More information about the `}<inlineCode parentName="p">{`<UiSpacing />`}</inlineCode>{` implementation.`}</p>
    <h3 {...{
      "id": "spacingdistribution"
    }}>{`SpacingDistribution`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/spacing/spacing.tsx#L8"
      }}>{`SpacingDistribution`}</a>{` let you set up spacing
in multiple ways:`}</p>
    <pre><code parentName="pre" {...{}}>{`type SpacingDistribution = {

  all?: Spacing;
  /** Apply spacing to all sides */

  block?: Spacing;
  /** Apply spacing to top and bottom */

  inline?: Spacing;
  /** Apply spacing to left and right */

  left?: Spacing;
  /** Apply spacing to left */

  right?: Spacing;
  /** Apply spacing to right */

  top?: Spacing;
  /** Apply spacing to top */

  bottom?: Spacing;
  /** Apply spacing to bottom */
};
`}</code></pre>
    <h3 {...{
      "id": "theme"
    }}>{`Theme`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/blob/main/packages/foundation/src/types/enums/spacing.ts"
      }}>{`Spacing type`}</a>{` is used to reference a
value from the `}<a parentName="p" {...{
        "href": "https://uireact.io/packages-foundation-docs-themes#spacing-"
      }}>{`Spacing`}</a>{` property provided from the themes`}</p>
    <p>{`For instance if you set up this in your theme:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  ...,
  spacing: {
    one: '1px',
    two: '2px',
    three: '4px',
    four: '8px',
    five: '16px',
    six: '32px',
    seven: '64px',
  },
  ...
}
`}</code></pre>
    <p>{`If you use this:`}</p>
    <pre><code parentName="pre" {...{}}>{`<UiSpacing padding={{all: 'three'}}>
  Some content
</UiSpacing>
`}</code></pre>
    <p>{`The content will get wrapped under a `}<inlineCode parentName="p">{`div`}</inlineCode>{` element with the CSS value of: `}<inlineCode parentName="p">{`padding: 4px;`}</inlineCode></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      